import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stringify, parse } from 'query-string';
import {
  GeoJSON, Map as WorldMap, ScaleControl, CircleMarker, Tooltip, Popup, TileLayer, Marker,
} from 'react-leaflet';
import { toPng } from 'html-to-image';
import {
  Button, Checkbox, Modal, Spin, Tag, Popover,
} from 'antd';
import StarRatings from 'react-star-ratings';
import { getCenterOfGeoJson, getNumberIcon } from './MapUtils'
import 'leaflet/dist/leaflet.css';
import { getCountryData } from '../GetJsonData';
import ScaleRange from '../ScaleRange'
import './styles.scss'
import { getFilterValuesAction } from '../../AddFilters/logic';
import {
  getShortListedMapBgDataAction,
  getShortListedMapBgDataResetAction,
  getShortListedMapMarkerDataResetAction,
  getShortListedMapMarkerDataAction,
  getProjectPiListAction,
  getProjectPiListResetAction,
  getProjectPiListToRenderAction,
  getProjectPiListToRenderMoreAction,
  getProjectPiListToRenderResetAction,
  addToSnippetViewAction,
  getSnippetViewAction,
  deletePiFromSnippetViewAction,
} from './logic';
import ColumnFilters from '../../RunAnalysis/columnFilters'
import AddFilters from '../../AddFilters';
import { getFormattedDate, getCookie } from '../../../../utils';
import { addPiAction, addPiResetAction } from '../../DocumentList/logic';
import Loader from '../../../../common/components/Loader';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
// import MapSnippetIcons from '../MapSnippetIcons';
// import  from './MapUtils'
export default function ChoroplethMapDrillDown({
  project_id, projectFilters,
}) {
  const dispatch = useDispatch()
  const adminType = getCookie('permissions')
  const COUNTRY_VIEW_ID = 'worlddata';
  // const defaultColors = ['#489558', '#B1D778', '#DEED97', '#FAE096', '#d9a81c', '#E4754F', '#C74032', '#ec3913', '#b81f0b']
  // const defaultColors = ['#008373', '#007668', '#00695c', '#005c51', '#004f45', '#00423a', '#00342e', '#002722', '#001a17']
  const defaultColors = ['#cce6e6', '#B3dad5', '#99cdc7', '#80c1b9', '#66b5ab', '#4da89d', '#339c8f', '#1a8f81', '#008373']
  const views = ['world', 'country', 'state']
  const snippetViewPiKeys = {
    Tier: true,
    '# Industry Studies': true,
    'Competing Studies': false,
    '# PXL Studies': true,
    'Enrolled Subjects': false,
    Quality: true,
    'KOL Score': false,
    'Alliance Type': false,
    'Date Added': false,
    'Start Up Months': false,
    'Start Up Index': false,
    'Recruitment Factor': false,
    'Last Trial Start Date': false,
  }

  const bounds = [
    [-90, -180],
    [90, 180],
  ];

  const mapOptions = {
    worldCopyJump: false, // Prevent map wrapping
    maxBounds: bounds, // Set the map bounds
    maxBoundsViscosity: 1.0, // Enforce the bounds strictly
    zoomSnap: 0.1,
  };

  const shortListedMapBgData = useSelector((store) => store.getShortListedMapBgDataResponse);
  const shortListedMapMarkerData = useSelector((store) => store.getShortListedMapMarkerDataResponse);
  const savedProjectData = useSelector((store) => store.savedProjectResultsData);
  const ProjectPiList = useSelector((store) => store.getProjectPiList);
  const ProjectPiListToRender = useSelector((store) => store.projectPiListToRender);
  const addPiResponse = useSelector((store) => store.addPiResponse);
  const snippetViewResponse = useSelector((store) => store.snippetViewResponse)
  const addToSnippetViewResponse = useSelector((store) => store.addToSnippetViewResponse)
  const deletePiFromSnippetViewResponse = useSelector((store) => store.deletePiFromSnippetViewResponse)
  const numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

  const markerRef = useRef();
  const shortListedStateData = useRef({})
  const mapRef = useRef(null);
  const popupData = useRef([])
  const bgPiData = useRef([])
  const elementRef = useRef(null);
  const geoJsonRef = useRef(null);

  const [geoJsonId, setGeoJsonId] = useState(COUNTRY_VIEW_ID);
  const [mapData, setMapData] = useState(getCountryData(COUNTRY_VIEW_ID))
  const [minMaxScale, setMinMaxScale] = useState({ min: undefined, max: undefined })
  const [scaleSplit, setScaleSplit] = useState(7)
  const [scaleMaximize, setScaleMaximize] = useState(true)
  const [rangeObj, setRangeObj] = useState([])
  const [formState, setFormStates] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [isQueryFilterOpen, setIsQueryFilterOpen] = useState(false)
  const [queryFilterData, setQueryFilterData] = useState(undefined)
  const [currentView, setCurrentView] = useState(views[0])
  const [currentCountryName, setCurrentCountryName] = useState(undefined)
  const [currentStateName, setCurrentStateName] = useState(undefined)
  const [piListDataToRender, setPiListDataToRender] = useState([])
  const [savedViewPopupOpen, setSavedViewPopupOpen] = useState(false)
  const [viewsForPermanentPis, setViewsForPermanentPis] = useState([])
  const [pisForPermanentView, setPisForPermanentView] = useState([])
  const [isSnippetViewOn, setIsSnippetViewOn] = useState(false)
  const [isShowOtherPisOn, setIsShowOtherPisOn] = useState(false)
  const [from, setFrom] = useState(2)
  const [scaleEditOpen, setScaleEditOpen] = useState(false)
  const [snippetViewDeletePiModal, setSnippetViewDeletePiModal] = useState(false)
  const [snippetViewDeletePiObj, setSnippetViewDeletePiObj] = useState({})
  const [snippetPiColumns, setSnippetPiColumns] = useState({
    ...snippetViewPiKeys,
  });
  const [noSnippetDataModal, setNoSnippetDataModal] = useState(false)
  const [isDownloadingInProgress, setIsDownloadingInProgress] = useState(false)
  const [piFieldsPopover, setPiFieldsPopover] = useState(false)
  const [downloadPreviewModalOpen, setDownloadPreviewModalOpen] = useState(false)
  const [initialCriteriaModal, setInitialCriteriaModal] = useState(false)
  useEffect(() => {
    if (mapRef.current && geoJsonRef.current) {
      mapRef.current.leafletElement.fitBounds(
        geoJsonRef.current.leafletElement.getBounds(),
      );
    }
  });

  useEffect(() => {
    const mapAccessedId = JSON.parse(localStorage.getItem('map_accessed'))
    if (mapAccessedId !== project_id) {
      localStorage.setItem('map_accessed', JSON.stringify(project_id));
      setInitialCriteriaModal(true)
    }
    dispatch(getFilterValuesAction());
    dispatch(getShortListedMapBgDataAction({
      project_id,
      filters: JSON.stringify(queryFilterData),
    }))
    dispatch(getShortListedMapMarkerDataAction({
      project_id,
      filters: JSON.stringify(projectFilters),
    }))
  }, [])

  useEffect(() => {
    if (snippetViewResponse.flag) {
      console.log('here2--->', Object.keys(snippetViewResponse?.data?.data?.countries))
      if (Object.keys(snippetViewResponse?.data?.data?.countries).length === 0) {
        console.log('here--->')
        setNoSnippetDataModal(true)
      }
      if (currentCountryName) {
        setPisForPermanentView(snippetViewResponse?.data?.data?.countries[currentCountryName] || [])
      } else {
        setPisForPermanentView(snippetViewResponse?.data?.data?.countries?.world || [])
      }
    }
  }, [snippetViewResponse])

  useEffect(() => {
    if (addToSnippetViewResponse.flag) {
      mapRef.current.leafletElement.closePopup();
    }
  }, [addToSnippetViewResponse])
  useEffect(() => {
    setGeoJsonId(COUNTRY_VIEW_ID)
    popupData.current = []
    bgPiData.current = []
    setCurrentView(views[0])
    setRangeObj([])
    setMapData(getCountryData(COUNTRY_VIEW_ID))
    dispatch(getShortListedMapBgDataAction({
      project_id,
      filters: JSON.stringify(queryFilterData),
    }))
    dispatch(getShortListedMapMarkerDataAction({
      project_id,
      filters: JSON.stringify(projectFilters),
    }))
  }, [projectFilters])

  useEffect(() => {
    if (addPiResponse.flag) {
      mapRef.current.leafletElement.closePopup();
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [currentCountryName], states: [currentStateName] }),
        view: 'list',
        size: 700,
      }))
      dispatch(getProjectPiListAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...projectFilters, countries: [currentCountryName], states: [currentStateName] }),
        view: 'list',
        size: 700,
      }))
      dispatch(addPiResetAction({}))
    }
  }, [addPiResponse])
  useEffect(() => {
    if (shortListedMapBgData?.flag) {
      if (shortListedMapBgData?.data?.countMapData) {
        processScaleRange(shortListedMapBgData?.data?.minValues?.allPisCount || 0, shortListedMapBgData?.data?.maxValues?.allPisCount || 0)
        shortListedStateData.current = shortListedMapBgData?.data?.countMapData
        setMinMaxScale({ min: shortListedMapBgData?.data?.minValues?.allPisCount || 0, max: shortListedMapBgData?.data?.maxValues?.allPisCount || 0 })
        // setCurrentStateList(shortListedMapBgData?.data?.modifiedOriginalKeyMap)
        dispatch(getShortListedMapBgDataResetAction({}))
      } else if (shortListedMapBgData?.data?.data) {
        bgPiData.current = shortListedMapBgData?.data?.data
        setMinMaxScale({ min: 0, max: 0 })
      }
    }
  }, [JSON.stringify(shortListedMapBgData)])

  useEffect(() => {
    return () => {
      dispatch(getShortListedMapBgDataResetAction({}))
    }
  }, [])

  useEffect(() => {
    if (deletePiFromSnippetViewResponse.flag) {
      setSnippetViewDeletePiModal(false)
      setSnippetViewDeletePiObj({})
      if (currentView === 'world') {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: 'world',
          },
        }))
      } else if (currentCountryName) {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: currentCountryName,
          },
        }))
      }
    }
  }, [deletePiFromSnippetViewResponse])
  useEffect(() => {
    if (ProjectPiList?.flag) {
      if (ProjectPiList?.data?.data && currentView === 'state') {
        popupData.current = ProjectPiList?.data?.data
      }
      dispatch(getProjectPiListResetAction({}))
    }
  }, [JSON.stringify(ProjectPiList)])

  useEffect(() => {
    if (ProjectPiListToRender?.flag) {
      if (ProjectPiListToRender?.data) {
        setPiListDataToRender(ProjectPiListToRender?.data?.data)
      }
    }
  }, [JSON.stringify(ProjectPiListToRender)])

  useEffect(() => {
    if (shortListedMapMarkerData?.flag) {
      if (shortListedMapMarkerData?.data?.countMapData) {
        popupData.current = []
        const countrydata = getCountryData(currentView === 'country' ? processName(currentCountryName) : geoJsonId).features
        countrydata.map((e) => {
          if (Object.keys(shortListedMapMarkerData?.data?.countMapData).includes(processName(e?.properties?.NAME_1))) {
            if (shortListedMapMarkerData?.data?.countMapData[processName(e?.properties?.NAME_1)]?.projectPisCount) {
              popupData.current.push({ ...shortListedMapMarkerData?.data?.countMapData[processName(e?.properties?.NAME_1)], center: getCenterOfGeoJson(e) })
            }
          }
          return null
        })
        dispatch(getShortListedMapMarkerDataResetAction({}))
      }
    }
  }, [JSON.stringify(shortListedMapMarkerData)])

  const mapCenter = getCenterOfGeoJson(mapData);

  const processName = (e) => {
    return e.toLowerCase().replaceAll(' ', '').replaceAll('-', '')
  }

  const onDrillDown = (e) => {
    setCurrentView(views[views.indexOf(currentView) + 1 ? views.indexOf(currentView) + 1 : 2])
    if (getCountryData(processName(e.target.feature?.properties?.NAME_1))) {
      setRangeObj([])
      setMinMaxScale({ min: undefined, max: undefined })
      setCurrentCountryName(e.target.feature?.properties?.NAME_1)
      setMapData(getCountryData(processName(e.target.feature?.properties?.NAME_1)))
      setGeoJsonId(processName(e.target.feature?.properties?.NAME_1));
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [e.target.feature?.properties?.NAME_1] }),
      }))
      dispatch(getShortListedMapMarkerDataAction({
        project_id,
        scale: 'states',
        country_name: e.target.feature?.properties?.NAME_1,
        filters: JSON.stringify(projectFilters),
      }))
      if (isSnippetViewOn) {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: e.target.feature?.properties?.NAME_1,
          },
        }))
      }
    } else if (views[views.indexOf(currentView) + 1 ? views.indexOf(currentView) + 1 : 2] === 'state' && currentCountryName) {
      setMapData(e.target.feature)
      setGeoJsonId(processName(e.target.feature?.properties?.NAME_1));
      // const currentState = currentStateList[processName(e.target.feature?.properties?.NAME_1)]
      setCurrentStateName(e.target.feature?.properties?.NAME_1)
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [currentCountryName], states: [e.target.feature?.properties?.NAME_1] }),
        view: 'list',
        size: 700,
      }))
      dispatch(getProjectPiListAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...projectFilters, countries: [currentCountryName], states: [e.target.feature?.properties?.NAME_1] }),
        view: 'list',
        size: 700,
      }))
      // popupData.current = []
    } else {
      setMapData(e.target.feature)
      setGeoJsonId(processName(e.target.feature?.properties?.NAME_1));
    }
  };

  const deletePiFromSnippetConfirm = () => {
    dispatch(deletePiFromSnippetViewAction({
      body: {
        project_id,
        country_name: currentCountryName || 'world',
        pi_id: snippetViewDeletePiObj.id,
      },
    }))
  }

  const setFormState = (e) => {
    if (e) {
      setFormStates(e)
    } else { setFormStates([]) }
  }

  const randomHexColor = () => {
    const letters = '0123456789ABCDEF';
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[(Math.floor(crypto.getRandomValues(array) * 16))];
    }
    return color
  }

  const processScaleRange = (minVal, Maxval) => {
    if (rangeObj.length === 0) {
      let lowerBound = 0
      let upperBound = 0
      let id = 0
      const temp = rangeObj
      const diff = Math.round((Maxval - minVal) / scaleSplit)
      while (upperBound <= Maxval) {
        upperBound = lowerBound + diff
        const tempRangeObj = {
          id,
          lowerBound,
          upperBound,
          color: defaultColors[id] ? defaultColors[id] : randomHexColor(),
        }
        temp.push(tempRangeObj)
        lowerBound = upperBound + 1
        id += 1
      }
      setRangeObj(temp)
    }
  }

  function getColor(d) {
    // const obj = rangeObj.find((itm) => ((itm.lowerBound <= d) && (d <= itm.lowerBound)))
    for (let i = 0; i < rangeObj.length; i += 1) {
      if (rangeObj[i].lowerBound <= d) {
        if (rangeObj[i].upperBound >= d) {
          return rangeObj[i].color
        }
      }
    }
    return '#00000'
  }

  const applyPiFields = (columnsObj) => {
    setSnippetPiColumns(columnsObj);
    setPiFieldsPopover(false);
  };

  const handlebackClicked = () => {
    if (currentView === 'state' && getCountryData(processName(currentCountryName))) {
      setRangeObj([])
      setCurrentView(views[views.indexOf(currentView) - 1 ? views.indexOf(currentView) - 1 : 0])
      setMinMaxScale({ min: undefined, max: undefined })
      setMapData(getCountryData(processName(currentCountryName)))
      setGeoJsonId(processName(currentCountryName));
      dispatch(getShortListedMapBgDataAction({
        project_id,
        scale: 'states',
        filters: JSON.stringify({ ...queryFilterData, countries: [currentCountryName] }),
      }))
      dispatch(getShortListedMapMarkerDataAction({
        project_id,
        scale: 'states',
        country_name: currentCountryName,
        filters: JSON.stringify(projectFilters),
      }))
      if (isSnippetViewOn) {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: currentCountryName,
          },
        }))
      }
    } else {
      setCurrentView(views[views.indexOf(currentView) - 1 ? views.indexOf(currentView) - 1 : 0])
      setCurrentCountryName(undefined)
      setRangeObj([])
      setMinMaxScale({ min: undefined, max: undefined })
      dispatch(getShortListedMapBgDataAction({
        project_id,
        filters: JSON.stringify(queryFilterData),
      }))
      dispatch(getShortListedMapMarkerDataAction({
        project_id,
        filters: JSON.stringify(projectFilters),
      }))
      if (isSnippetViewOn) {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: 'world',
          },
        }))
      }
      setMapData(getCountryData('worlddata'))
      setGeoJsonId('worlddata');
    }
    bgPiData.current = []
    popupData.current = []
  }

  const getValue = (val) => {
    const temp = formState.filter((itm) => itm.type === val);
    if (val === 'Protocol Title') {
      return temp[0]?.data || undefined;
    }
    if (val === 'Alliance' || val === 'Parexel Study Exists') {
      if ((temp[0] && temp[0].data && temp[0].data === 'yes') || (temp[0] && temp[0].data && temp[0].data === 'Yes')) {
        return 'Yes';
      }
      if ((temp[0] && temp[0].data && temp[0].data === 'no') || (temp[0] && temp[0].data && temp[0].data === 'No')) {
        return 'No';
      }
      return undefined;
    }
    if (val === 'Study IDs') {
      if (Array.isArray(temp[0]?.data)) {
        return temp[0]?.data
      }
      const a = temp[0]?.data
      if (a) {
        return a.split(',')
      }
    }
    if (Array.isArray(temp[0]?.data) && temp[0]?.data.length) {
      return temp[0]?.data
    } if (typeof temp[0]?.data === 'string' && temp[0]?.data !== 0) {
      return temp[0]?.data
    } return undefined
  };

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1;
    }
    if (value === 'Class 2') {
      return 2;
    }
    if (value === 'Class 1') {
      return 3;
    }
    return 0;
  };

  const handleQueryFilterApply = () => {
    setGeoJsonId(COUNTRY_VIEW_ID)
    popupData.current = []
    bgPiData.current = []
    setCurrentView(views[0])
    setRangeObj([])
    setMapData(getCountryData(COUNTRY_VIEW_ID))
    const filters = {
      indications: getValue('Therapeutic Interest') || undefined,
      indications_from_trials: getValue('Indications From Trials') || undefined,
      sponsors:
        getValue('Sponsor')?.map((itm) => {
          return { name: itm.value, affiliation_id: itm.key };
        }) || [],
      therapeutic_area: getValue('Therapy Area') || undefined,
      phases: getValue('Study Phase') || undefined,
      countries: getValue('Country') || undefined,
      alliance_type: getValue('Alliance Type') || undefined,
      all_trial_ids: getValue('Protocol Number / Trial ID') || undefined,
      pi_sources: getValue('PI Source') || undefined,
      sub_regions: getValue('Sub-Region') || [],
      primary_center_types: getValue('Primary Center Type') || undefined,
      regions: getValue('Region') || undefined,
      specialities: getValue('Speciality') || undefined,
      parexel_study_ids: getValue('Parexel Study ID') || undefined,
      age_range_groups: getValue('Age Range Group') || undefined,
      alliance: getValue('Alliance') || undefined,
      parexel_study: getValue('Parexel Study Exists') || undefined,
      pi_names: getValue('PI Name') || undefined,
      protocol_title: getValue('Protocol Title') || undefined,
      start_date: getValue('Start Date') || undefined,
      end_date: getValue('End Date') || undefined,
      tier: getValue('Profile Tier')?.map(Number) || undefined,
      compliance: getValue('Compliance') || undefined,
      study_ids: getValue('Study IDs') || undefined,
      networks_names: getValue('Investigator Network Names') || undefined,
      kol_score: Number(getValue('Kol Score')) || undefined,
      start_up: getValue('Start Up Months') || undefined,
      industry_studies: Number(getValue('Industry Studies')) || undefined,
      race_ethnicity: getValue('Race & Ethnicity') || undefined,
      income_by_insurance: getValue('Income by Insurance') || undefined,
      employment_by_insurance: getValue('Employment by Insurance') || undefined,
      center_name: getValue('Center Name') || undefined,
      alliance_name: getValue('Alliance Name') || undefined,
      parent_names: getValue('Parent Institution Names') || undefined,
      center_networks: getValue('Center Network Names') || undefined,
    }
    setQueryFilterData(filters)
    dispatch(getShortListedMapBgDataAction({
      filters: JSON.stringify(filters),
      scale: currentView === 'state' ? 'states' : undefined,
      country_name: currentView === 'state' ? currentCountryName : undefined,
    }))
    dispatch(getShortListedMapMarkerDataAction({
      filters: JSON.stringify(projectFilters),
      project_id,
    }))
  }

  function geoJSONStyle(e) {
    if (Object.keys(shortListedStateData.current).includes(processName(e?.properties?.NAME_1))) {
      return {
        color: '#1f2021',
        weight: 1,
        fillOpacity: currentView !== 'state' ? 0.7 : 0,
        fillColor: getColor(shortListedStateData.current[processName(e?.properties?.NAME_1)]?.allPisCount),
      };
    } return {
      color: '#1f2021',
      weight: 1,
      // fillOpacity: 0,
      fillOpacity: currentView !== 'state' ? 0.7 : 0,
      fillColor: '#808080',
    };
  }

  const handleAddPiToProject = (itm) => {
    popupData.current = []
    dispatch(addPiAction({
      project_id,
      site_id: itm.affiliated_site_id,
      pi_id: itm.id,
      is_cfl: adminType.includes('cfl_access') ? true : undefined,
      is_lcor: adminType.includes('lcor_access') ? true : undefined,
    }))
  }

  const handleAddPiToView = (itm) => {
    const params = {
      project_id,
    }
    const body = []
    if (viewsForPermanentPis.includes('country')) {
      body.push(
        {
          country_name: currentCountryName,
          pi_id: itm.id,
        },
      )
    }
    if (viewsForPermanentPis.includes('world')) {
      body.push(
        {
          country_name: 'world',
          pi_id: itm.id,
        },
      )
    }
    dispatch(addToSnippetViewAction({
      body,
      params,
    }))
  }

  const getPiCard = (item, type) => {
    return !savedViewPopupOpen ? (
      <div className="popUp-second">
        <div className="pimap-main-anlysis-name-wrapper">
          {item.name}
          {type !== 'non_project' && (<div className="checked-in-project" title="This Investigator is present in project" />)}
        </div>
        <div className="popUp-fifth map-popup-pis-list-wrap">
          <div className="map-popup-pis-list map-result-list-card">
            <div className="map-popup-pi-card-wrap map-popup-pi-card-wrap-anl">
              <a
                className="map-result-list-card-title map-result-list-card-title-link"
                title={item?.pi_name}
                target="_blank"
                href={`/search/investigatorprofile?${stringify({
                  query: item?.pi_name,
                  id: item?.pi_id,
                  type: 'PI',
                })}`}
                rel="noreferrer noopener"
              >
                {item?.pi_name}
              </a>
              <div className="map-result-list-card-content">
                <div className="content-title">Primary Organization: </div>
                <div className="map-location">{item?.primary_organization ? item?.primary_organization.name : '-'}</div>
                <div className="content-title">Address: </div>
                <div className="map-location">{item?.primary_organization ? `${item?.primary_organization.city}, ${item?.primary_organization.state}, ${item?.primary_organization.country}, ${item?.primary_organization.zip}` : '-'}</div>
                <div className="content-title">LandSCAPE id: </div>
                <div className="map-location">{item?.landscape_id ? item?.landscape_id : '-'}</div>
              </div>
              <div className="map-result-list-card-rating">
                <div className="rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability: </div>
                    {item.reliability_class ? (
                      <StarRatings
                        rating={getStars(item.reliability_class)}
                        starRatedColor="#8A0050"
                        numberOfStars={3}
                        starDimension="16px"
                        starSpacing="1px"
                        starEmptyColor="#y"
                      />
                    ) : (
                      <StarRatings
                        rating={0}
                        starRatedColor="#8A0050"
                        numberOfStars={3}
                        starDimension="16px"
                        starSpacing="1px"
                        starEmptyColor="#D099B9"
                      />
                    )}
                  </div>
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Quality:</div>
                    {item?.quality_rating !== ''
                      ? (
                        <StarRatings
                          rating={item?.quality_rating}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="dash-nodata">-</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="map-result-list-card-content">
                <div className="content-title">Specialities:</div>
                {item?.specialties && item?.specialties.length
                  ? (
                    <div>{item?.specialties.join('; ')}</div>
                  )
                  : (
                    <div className="dash-nodata">-</div>
                  )}
              </div>
              <div className="map-result-list-card-content">
                <div className="content-title">Last Trial Start Date:</div>
                {item?.recent_trial_start_date_ts
                  ? (
                    <div>{getFormattedDate(item?.recent_trial_start_date_ts * 1000)}</div>
                  )
                  : (
                    <div className="dash-nodata">-</div>
                  )}
              </div>
              <div className="map-result-list-card-content">
                <div className="map-tag">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag"># Industry Studies</div>
                      <div className="sizeOftag">{item?.industry_studies}</div>
                    </div>
                  </Tag>
                  {item?.tier !== ''
                    ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Tier</div>
                          <div className="sizeOftag">{item?.tier}</div>
                        </div>
                      </Tag>
                    )
                    : null}
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag" title="KOL scores are derived based on research/information authored by the investigator like publications, clinical trials, congress articles, patents, grants, guidelines etc. Each document is scored considering several factors like type of document, recency of the document, role of the investigator in a given document, source where the document is published etc. Scores for each individual document is aggregated and weighted by data class, to calculate the raw KOL score. The raw KOL score for the investigators is then numerically scaled between 0 to 100 considering rest of the investigators in LandSCAPE.">KOL Score</div>
                      <div className="sizeOftag">{item?.cls_kol_score}</div>
                    </div>
                  </Tag>
                </div>
              </div>
            </div>
            {type === 'non_project'
              ? (<div className="add-pi-to-project-btn"> <Button onClick={() => handleAddPiToProject(item)}>Add to Project</Button></div>
              ) : currentView === 'state' ? (<div className="add-pi-to-project-btn"> <Button onClick={() => { setViewsForPermanentPis([]); setSavedViewPopupOpen(true) }}>Add to Snippet View</Button></div>) : null}
          </div>
        </div>
      </div>
    ) : (
      <div className="popUp-second">
        <div className="pimap-main-anlysis-name-wrapper">
          {item.name}
          <div className="checked-in-project" title="This Investigator is present in project" />
        </div>
        <div className="pimap-main-save-to-project-text">
          On which view you want this Investigator to display?
          <Checkbox.Group style={{ paddingTop: '30px' }} onChange={(e) => { setViewsForPermanentPis(e) }}>
            <Checkbox value="world">Global</Checkbox>
            <Checkbox value="country">Country  ({currentCountryName})</Checkbox>
          </Checkbox.Group>
        </div>
        <div className="popUp-fifth map-popup-pis-list-wrap">
          <div className="map-popup-pis-list map-result-list-card">
            <div className="add-pi-to-project-btn"> <Button disabled={viewsForPermanentPis.length === 0} onClick={() => handleAddPiToView(item)}>Done</Button></div>
          </div>
        </div>
      </div>
    )
  }

  const popUpData = (item, type) => {
    return (
      <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false} onClose={() => setSavedViewPopupOpen(false)}>
        <div
          className="popUp-first"
          tabIndex={0}
          role="button"
          onKeyDown={null}
        >
          {getPiCard(item, type)}
        </div>
      </Popup>
    );
  };

  const ListApiCall = (item) => {
    if (currentView === 'country' && (currentCountryName === null || currentCountryName === undefined)) {
      dispatch(getProjectPiListToRenderAction({
        project_id,
        scale: currentView === 'country' ? 'states' : 'countries',
        filters: JSON.stringify({ ...projectFilters, countries: [item?.name] }),
        view: 'list',
        size: 5000,
      }))
    } else {
      dispatch(getProjectPiListToRenderAction({
        project_id,
        scale: currentView === 'country' ? 'states' : 'countries',
        filters: JSON.stringify({ ...projectFilters, countries: currentView === 'country' ? [currentCountryName] : [item?.name], states: currentView === 'country' ? [item?.name] : undefined }),
        view: 'list',
        size: 5000,
      }))
    }
  }

  const loadMoreRows = () => {
    dispatch(getProjectPiListToRenderMoreAction({
      project_id,
      scale: 'countries',
      filters: JSON.stringify({ ...projectFilters, countries: ['India'] }),
      view: 'list',
      size: 5000,
      from,
    }))
    setFrom(from + 1)
  }

  const handleListTooltipClose = () => {
    getProjectPiListToRenderResetAction({})
    setFrom(2)
  }

  const renderListOfPis = (item) => {
    return (
      <Popup
        className="pimap-main pimap-main-anlysis map-center"
        minWidth={400}
        closeButton={false}
        onOpen={() => {
          ListApiCall(item)
        }}
        onClose={() => {
          handleListTooltipClose()
        }}
      >
        <Loader loading={ProjectPiListToRender.loading} style={{ height: '100px' }}>
          <div className="title-pi-card-List">{currentView === 'country' && currentCountryName !== null && currentCountryName !== undefined ? `${item?.name}, ${currentCountryName}` : item?.name}</div>
          <LazyLoadDiv
            className="card-list scrollbar"
            id="analysis-list"
            total={ProjectPiListToRender.data.total}
            currentTotal={(piListDataToRender || []).length}
            loadMoreRows={() => loadMoreRows()}
          >
            <div
              className="popUp-first"
              tabIndex={0}
              role="button"
              onKeyDown={null}
            >
              {piListDataToRender.length && piListDataToRender.map((itm) => (getPiCard(itm, 'in_project')))}
            </div>
          </LazyLoadDiv>
          <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
            <div className="show-doc-data">
              Showing<span className="show-count">{piListDataToRender?.length}</span> out of
              <span className="show-count-number">{ProjectPiListToRender.data.total}</span>
            </div>
          </div>
        </Loader>
      </Popup>
    )
  }

  const showScale = () => {
    return (
      <Spin spinning={shortListedMapBgData.loading || savedProjectData.loading || shortListedMapMarkerData.loading || addToSnippetViewResponse.loading} error={shortListedMapBgData.error}>
        <div className="mapMainContainer-scale-content-in">
          {rangeObj.map((itm) => (
            <div className="mapMainContainer-scale-content-in-sec" id={itm.id}>
              <div className="mapMainContainer-scale-color" style={{ backgroundColor: itm.color }} />
              <div className="mapMainContainer-scale-range">
                {itm.lowerBound} - {itm.upperBound > minMaxScale.max ? `${minMaxScale.max}+` : itm.upperBound}
              </div>
            </div>
          ))}
          <div className="mapMainContainer-scale-content-in-sec">
            <div className="mapMainContainer-scale-color" style={{ backgroundColor: '#808080' }} />
            <div className="mapMainContainer-scale-range">
              Data Unavailable
            </div>
          </div>
        </div>
      </Spin>
    )
  }

  const checkColumnDisplay = (columnName) => {
    const viewedKeys = Object.keys(snippetPiColumns).filter((key) => snippetPiColumns[key]);
    return viewedKeys.indexOf(columnName) !== -1;
  };

  const handleSnippetViewClicked = () => {
    if (isSnippetViewOn) {
      setIsSnippetViewOn(false)
    } else {
      setIsSnippetViewOn(true)
      if (currentView === 'world') {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: 'world',
          },
        }))
      } else if (currentCountryName) {
        dispatch(getSnippetViewAction({
          params: {
            project_id,
            country_name: currentCountryName,
          },
        }))
      }
    }
  }

  const handleDownloadViewClicked = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        const filename = currentCountryName ? `${currentCountryName}_snippet_view.png` : 'global_snippet_view.png'
        link.download = filename;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setIsDownloadingInProgress(false)
      setDownloadPreviewModalOpen(false)
    }, 2000)
  }

  const handleSnippetPiDeleteClick = (itm) => {
    setSnippetViewDeletePiModal(true)
    setSnippetViewDeletePiObj(itm)
  }

  const renderPiCard = (itm) => {
    return (
      <>
        <div className="snippet-pi-number" />
        {!downloadPreviewModalOpen && (<div className="snippet-pi-remove"><div className="snippet-pi-remove-btn" role="presentation" onClick={() => handleSnippetPiDeleteClick(itm)} /></div>)}
        <div className="snippet-pi-card-combine">
          <div className="snippet-pi-card-combine-img">
            <div className="snippet-pi-img" />
          </div>
          <div className="snippet-pi-card-combine-content">
            <div className="snippet-pi-info-name">
              <a
                target="_blank"
                href={`/search/investigatorprofile?${stringify({
                  ...parse(location.search),
                  id: itm.id,
                  type: itm.type,
                })}`}
                rel="noopener noreferrer"
                title={itm.name}
              >
                {itm.name}
              </a>
            </div>
            <div className="snippet-pi-info-center-name">
              {itm.primary_organization?.name}
            </div>
          </div>
        </div>
        <div className="snippet-pi-info-section">
          {checkColumnDisplay('Tier') ? (
            <div className="snippet-pi-info-section-in">
              <>
                <div className="snippet-pi-info-section-label">
                  Tier:
                </div>
                <div className="snippet-pi-info-section-value">
                  {itm.tier ? itm.tier : '-'}
                </div>
              </>
            </div>
          ) : null}
          {checkColumnDisplay('# Industry Studies') ? (
            <div className="snippet-pi-info-section-in">
              <>
                <div className="snippet-pi-info-section-label">
                  Industry Studies:
                </div>
                <div className="snippet-pi-info-section-value">
                  {itm.industry_studies}
                </div>
              </>
            </div>
          ) : null}
          {checkColumnDisplay('Competing Studies') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                # Competing Studies:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.competing_studies ? itm.competing_studies : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('# PXL Studies') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                # PXL Studies:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.pxl_studies ? itm.pxl_studies : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Enrolled Subjects') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Enrolled Subjects:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.enrolled_count ? itm.enrolled_count : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Quality') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Quality:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.quality_rating ? itm.quality_rating : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('KOL Score') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                KOL Score:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.cls_kol_score ? itm.cls_kol_score : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Alliance Type') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Alliance Type:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.alliance_type ? itm.alliance_type : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Date Added') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Date Added:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.date_added
                  ? getFormattedDate(itm.date_added * 1000)
                  : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Start Up Months') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Start Up Months:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.p_start_up ? itm.p_start_up.toFixed(2) : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Start Up Index') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Start Up Index:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.startup_index ? itm.startup_index : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Recruitment Factor') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Recruitment Factor:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.p_recruitment ? itm.p_recruitment : '-'}
              </div>
            </div>
          ) : null}
          {checkColumnDisplay('Last Trial Start Date') ? (
            <div className="snippet-pi-info-section-in">
              <div className="snippet-pi-info-section-label">
                Last Trial Start Date:
              </div>
              <div className="snippet-pi-info-section-value">
                {itm.recent_trial_start_date_ts
                  ? getFormattedDate(itm.recent_trial_start_date_ts * 1000)
                  : '-'}
              </div>
            </div>
          ) : null}
        </div>
      </>
    )
  }

  const renderSnippetPis = () => {
    let leftPis = []
    let rightPis = []
    console.log('pisForPermanentView---->', pisForPermanentView)
    leftPis = pisForPermanentView.length && pisForPermanentView.slice(0, 5)
    rightPis = pisForPermanentView.length && pisForPermanentView.slice(5, 10)
    return (
      <>
        <div className="snippet-view-left-section">
          {
            leftPis.length && leftPis.map((itm, index) => (
              <div className={`snippet-pi-card num-${numbers[index]}`} id={`r-${index}`}>
                {renderPiCard(itm)}
              </div>
            ))
          }
        </div>
        <div className="snippet-view-right-section">
          {
            rightPis.length && rightPis.map((itm, index) => (
              <div className={`snippet-pi-card num-${numbers[index + 5]}`} id={`b-${index}`}>
                {renderPiCard(itm)}
              </div>
            ))
          }
        </div>
      </>
    )
  }
  const renderSnippetMarkers = () => {
    return pisForPermanentView.map((itm, index) => (
      <Marker
        position={[itm?.primary_organization?.lat, itm?.primary_organization?.lon]}
        key={index}
        icon={getNumberIcon(index + 1)}
      />
    ))
  }

  return (
    <Spin spinning={shortListedMapBgData.loading || savedProjectData.loading || shortListedMapMarkerData.loading || addPiResponse.loading || addToSnippetViewResponse.loading || snippetViewResponse.loading} error={shortListedMapBgData.error}>
      <div className="mapMainContainer" id="mapMainContainer" ref={elementRef}>
        <div className="mapMainContainer-filter-container">
          {!isQueryFilterOpen && !isSnippetViewOn && (
            <div className="filter-button-open" role="presentation" onClick={() => { setIsQueryFilterOpen(true) }}>
              <div className="filter-button-img" />
              <div className="filter-open-arrow-img" />
            </div>
          )}
          {isQueryFilterOpen
            && (
              <div className="mapMainContainer-filter-section">
                <div className="mapMainContainer-filter-content">
                  <div className="title">Filters</div>
                  <div className="query-wrap-section">
                    <div className="query-wrap open">
                      <div className="project-select-tags">
                        <><AddFilters
                          forms={{ formState, setFormState }}
                          filters={{ filterValues, setFilterValues }}
                        />
                          <div className="footer-button-wrapper footer-button-wrapper-pd">
                            <Button
                              className="cancel-wrapper cancel-btn ant-btn-default"
                              onClick={() => {
                                setIsQueryFilterOpen(false)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              className="save-wrapper primary-btn ant-btn-primary"
                              onClick={() => {
                                handleQueryFilterApply()
                                setIsQueryFilterOpen(false)
                              }}
                            >
                              Filter
                            </Button>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-close-arrow-img" role="presentation" onClick={() => setIsQueryFilterOpen(false)} />
              </div>
            )}
        </div>
        <WorldMap className="map" center={mapCenter} zoom={2} minZoom={currentView === 'world' ? 2 : 3} ref={mapRef} zoomControl={false} {...mapOptions}>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            // attribution= downloadPreviewModalOpen ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            minZoom={2}
            maxZoom={19}
          />
          <GeoJSON
            data={mapData}
            key={geoJsonId}
            style={(e) => geoJSONStyle(e)}
            onEachFeature={currentView !== 'state' ? onEachFeature : null}
            ref={geoJsonRef}
            id={geoJsonId}
          />
          {geoJsonId !== 'worlddata'
            && !downloadPreviewModalOpen && (
              <div className="map-back-screen-button">
                <Button className="back-btn" id="map-back-btn" type="primary" onClick={() => handlebackClicked()}>
                  back
                </Button>
              </div>
          )}
          {!downloadPreviewModalOpen && (
          <div className="map-snippet-screen-button">
            <Button className={`snippet-btn ${isSnippetViewOn ? 'active' : ''}`} id="map-snippet-btn" type="primary" onClick={() => handleSnippetViewClicked()}>
              Snippet View
            </Button>
          </div>
          )}
          {currentView === 'state' && !isSnippetViewOn && (
          <div className="map-show-other-pis-button">
            <Button className={`other-pis-btn ${isShowOtherPisOn ? 'active' : ''}`} id="map-snippet-btn" type="primary" onClick={() => setIsShowOtherPisOn(!isShowOtherPisOn)}>
              Show Other Pis
            </Button>
          </div>
          )}
          {isSnippetViewOn && !downloadPreviewModalOpen && (
            <div className="show-hide-wrap">
              <Popover
                overlayClassName="filter-show-hide-wrap"
                content={(
                  <div>
                    <ColumnFilters
                      applyColumns={applyPiFields}
                      displayColumns={snippetPiColumns}
                      pageName="snippetView"
                    />
                  </div>
)}
                title="Show/Hide Columns"
                trigger="click"
                open={piFieldsPopover}
                onOpenChange={(e) => setPiFieldsPopover(e)}
                placement="left"
              >
                <div className="map-snippet-pi-field-btn">
                  <Button className="pi-field-btn" id="map-pi-field-btn" type="primary">
                    Pi Fields
                  </Button>
                </div>
              </Popover>
            </div>
          )}
          {isSnippetViewOn && !downloadPreviewModalOpen && (
          <div className="map-snippet-screenshot-btn">
            <Button className="screenshot-btn" id="map-snippet-btn" type="primary" onClick={() => setDownloadPreviewModalOpen(true)}>
              Download Current View
            </Button>
          </div>
          )}
          {popupData.current.length && !isSnippetViewOn
            ? popupData?.current.map((item, ind) => (
              <CircleMarker
                center={item?.center ? item?.center : item?.primary_organization?.lat && item?.primary_organization?.lon ? [item?.primary_organization?.lat, item?.primary_organization?.lon] : [0, 0]}
                markerRef={markerRef}
                fillOpacity={0.5}
                className="circle-marker-map"
                radius={10}
                key={ind}
              >
                <Tooltip
                  direction="top"
                  permanent
                  interactive
                  className="run-analysis-map-tooltip"
                >
                  {item?.projectPisCount ? item?.projectPisCount
                    : (
                      <div className="checked-in-project" />
                    )}
                </Tooltip>
                {item?.projectPisCount ? renderListOfPis(item) : popUpData(item)}
              </CircleMarker>
            )) : null}
          {bgPiData.current.length && !isSnippetViewOn && isShowOtherPisOn
            ? bgPiData?.current.map((item, ind) => {
              const center = item?.center ? item?.center : [item?.primary_organization?.lat, item?.primary_organization?.lon]
              return center.length > 0 && center[0] !== undefined && center[1] !== undefined ? (
                <CircleMarker
                  center={center}
                  markerRef={markerRef}
                  fillOpacity={1}
                  className="circle-marker-map-background-data"
                  radius={10}
                  key={ind}
                >
                  {popUpData(item, 'non_project')}
                </CircleMarker>
              ) : null
            }) : null}
          {isSnippetViewOn && (renderSnippetPis())}
          {isSnippetViewOn && (renderSnippetMarkers())}
          {/* {isSnippetViewOn && ({renderSnippetLines()})} */}
          <ScaleControl />
        </WorldMap>
        {!isSnippetViewOn && (
          <div className="mapMainContainer-scale">
            <div className="mapMainContainer-scale-main">
              {currentView !== 'state'
                ? (
                  <>
                    <div className={`head-col${scaleMaximize ? 'open head-col' : 'close head-col'}`}>
                      <div className="scale-title"># Investigators</div>
                      <div className="scale-dropdown">
                        <Button
                          className={`scale-maximize-icon${scaleMaximize ? 'open' : 'close'}`}
                          onClick={() => {
                            setScaleMaximize(!scaleMaximize)
                          }}
                          type="primary"
                        />
                      </div>
                    </div>
                    {scaleMaximize && !isSnippetViewOn && (
                      <div className="mapMainContainer-scale-content">
                        {showScale()}
                        <div className="mapMainContainer-scale-edit-button" role="presentation" onClick={() => setScaleEditOpen(true)} />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className={`head-col${scaleMaximize ? 'open head-col' : 'close head-col'}`}>
                      <div className="scale-title">Legends</div>
                      <div className="scale-dropdown">
                        <Button
                          className={`scale-maximize-icon${scaleMaximize ? 'open' : 'close'}`}
                          onClick={() => {
                            setScaleMaximize(!scaleMaximize)
                          }}
                          type="primary"
                        />
                      </div>
                    </div>
                    {scaleMaximize && (
                      <div className="mapMainContainer-scale-content">
                        <div className="marker-section-parent">
                          <div className="marker-section">
                            <div className="marker-inproject-icon marker-icon" />
                            <div className="marker-inproject-label marker-label">
                              Investigators present <br />
                              in project
                            </div>
                          </div>
                          <div className="marker-section">
                            <div className="marker-in-project-icon marker-icon" />
                            <div className="marker-not-in-project-label marker-label">
                              Investigators not present <br />
                              in project
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
          </div>
        )}
        {scaleEditOpen && (
          <Modal
            className="scale-modal"
            open={scaleEditOpen}
            title="Scale Range"
            onCancel={() => setScaleEditOpen(false)}
            footer={null}
          >
            <ScaleRange minMaxScale={minMaxScale} setMinMaxScale={setMinMaxScale} rangeObj={rangeObj} setRangeobj={setRangeObj} closePopover={setScaleEditOpen} scaleSplit={scaleSplit} setScaleSplit={setScaleSplit} />
          </Modal>
        )}
        {snippetViewDeletePiModal && (
        <Modal
          visible={snippetViewDeletePiModal}
          onCancel={() => setSnippetViewDeletePiModal(false)}
          onOk={() => deletePiFromSnippetConfirm()}
          title="Remove PI from Snippet View Confirmation"
          okText="Yes"
          cancelText="No"
          className="unsaved-changes-modal"
        >
          <Loader loading={deletePiFromSnippetViewResponse.loading}>
            <div className="unsave-changes-modal-main">
              <div className="unsave-changes-modal-text">
                Do you really want to remove this investigator from snippet view ({currentCountryName ? `${currentCountryName}/Country Level` : 'Global Level'}) ?
              </div>
            </div>
          </Loader>
        </Modal>
        )}
        {downloadPreviewModalOpen && (
        <Modal
          visible={downloadPreviewModalOpen}
          onCancel={() => setDownloadPreviewModalOpen(false)}
          onOk={() => { setIsDownloadingInProgress(true); handleDownloadViewClicked() }}
          title="Download Current View Confirmation"
          okText="Yes"
          cancelText="No"
          className="download-preview-modal"
        >
          <Loader loading={isDownloadingInProgress}>
            <div className="unsave-changes-modal-main">
              <div className="unsave-changes-modal-text">
                Do you really want to download current snippet view ?
              </div>
            </div>
          </Loader>
        </Modal>
        )}
        {
          noSnippetDataModal && (
          <Modal
            visible={noSnippetDataModal}
            onCancel={() => setNoSnippetDataModal(false)}
            onOk={() => { setIsSnippetViewOn(false); setNoSnippetDataModal(false) }}
            title="No Snippet Data"
            okText="Exit Snippet View"
            className="unsaved-changes-modal"
          >
            <div className="unsave-changes-modal-main">
              <div className="unsave-changes-modal-text">
                You do not have investigators in snippet view
              </div>
            </div>
          </Modal>
          )
        }
        {
          initialCriteriaModal && (
            <Modal
              visible={initialCriteriaModal}
              onCancel={() => setInitialCriteriaModal(false)}
              onOk={() => { setIsQueryFilterOpen(true); setInitialCriteriaModal(false) }}
              title="Initial Heatmap Criteria"
              okText="Yes"
              cancelText="No"
              className="unsaved-changes-modal"
            >
              <div className="unsave-changes-modal-main">
                <div className="unsave-changes-modal-text">
                  Do you want to define the criteria for background heatmap ?
                </div>
              </div>
            </Modal>
          )
        }
      </div>
    </Spin>
  );
  function onEachFeature(e, layer) {
    // const layerUtils = layersUtils(geoJsonRef, mapRef);
    layer.on({
      // mouseover: layerUtils.highlightOnClick,
      // mouseout: layerUtils.resetHighlight,
      click: onDrillDown,
    });
  }
}
