import Modal from 'antd/lib/modal/Modal'
import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Tag, Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import {
  approvalMergeAction,
  getCardDataAction, getMergeDocAction, mergePreRequestAction, mergeRequestAction, mergeRequestResetAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import Comment from '../Comment';
import { getCookie } from '../../../utils';
import BarChart from '../../../common/components/BarChart';

const tagTitle = {
  tier: 'Tier',
  compliance: 'Compliance',
  alliance: 'Alliance type',
  indications: 'Indications',
  total_number_of_popis: 'Total PIs',
  industry_studies: 'Industry studies',
  interventional_studies: 'Interventional studies',
  observational_studies: 'Observational studies',
  parexel_studies: 'Parexel studies',
  enrollment_subjects: 'Enrolled subjects',
};

const MergeViewModal = ({
  type, data, view, isMergeModalOpen, setIsMergeModalOpen, mergedId, commentCnt, status, fromReports, setCenterForMerging, setPiForMerging, preRequest,
}) => {
  const dispatch = useDispatch()
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [centerUpdatedFields, setCenterUpdatedFields] = useState({
    name: {
      doc_id: undefined,
      value: undefined,
    },
    email: {
      doc_id: undefined,
      value: undefined,
    },
    survey_email: {
      doc_id: undefined,
      value: undefined,
    },
    other_email: {
      doc_id: undefined,
      value: undefined,
    },
    phone_number: {
      doc_id: undefined,
      value: undefined,
    },
    mobile_number: {
      doc_id: undefined,
      value: undefined,
    },
    address: {
      doc_id: undefined,
      value: undefined,
    },
  })

  const [piUpdatedFields, setPiUpdatedFields] = useState({
    name: {
      doc_id: undefined,
      value: undefined,
    },
    primary_email: {
      doc_id: undefined,
      value: undefined,
    },
    survey_email: {
      doc_id: undefined,
      value: undefined,
    },
    other_email: {
      doc_id: undefined,
      value: undefined,
    },
    phone_number: {
      doc_id: undefined,
      value: undefined,
    },
    mobile_number: {
      doc_id: undefined,
      value: undefined,
    },
    address: {
      doc_id: undefined,
      value: undefined,
    },
  })
  const mergePreRequestData = useSelector((store) => store.mergePreRequestData)
  const mergeRequestFinalData = useSelector((store) => store.mergeRequestFinalData)
  const getCardData = useSelector((store) => store.getCardData)
  const getMergedDoc = useSelector((store) => store.getMergeDoc)
  const adminType = getCookie('permissions')

  useEffect(() => {
    if (view === 'admin') {
      dispatch(getCardDataAction({
        innoplexus_id: mergedId,
      }))

      dispatch(getMergeDocAction({
        type: type === 'investigator' ? 'PI' : 'SITE',
        innoplexus_id: mergedId,
      }))
    }
  }, [])

  useEffect(() => {
    if (mergeRequestFinalData.flag) {
      setIsMergeModalOpen(false)
      dispatch(mergeRequestResetAction({}))
      if (type === 'center' || type === 'parent') {
        setCenterForMerging(
          {
            isCenterSelected: false,
            centerOneId: '',
            centerTwoId: [],
            centerOneData: {},
            centerTwoData: [],
          },
        )
      } else {
        setPiForMerging({
          isPiSelected: false,
          piOneId: '',
          piTwoId: [],
          piOneData: {},
          piTwoData: [],
        })
      }
    }
  }, [JSON.stringify(mergeRequestFinalData)])
  useEffect(() => {
    if (preRequest && (type === 'center' || type === 'parent')) {
      dispatch(mergePreRequestAction({
        type: 'SITE',
        body: {
          source_id: data?.centerTwoId,
          target_id: data?.centerOneId,
          updated_fields: centerUpdatedFields,
          parent_flag: type === 'parent',
        },
      }))
    }
  }, [centerUpdatedFields])

  useEffect(() => {
    if (preRequest && type === 'investigator') {
      dispatch(mergePreRequestAction({
        type: 'PI',
        body: {
          source_id: data?.piTwoId,
          target_id: data?.piOneId,
          updated_fields: piUpdatedFields,
          parent_flag: type === 'parent',
        },
      }))
    }
  }, [piUpdatedFields])

  const onConfirmClick = (doc) => {
    dispatch(mergeRequestAction({
      innoplexus_id: doc.innoplexus_id,
    }))
  }

  const showPieChart = (chartData) => {
    return (
      chartData
      && !chartData?.every((item) => {
        return item.value === 0;
      })
    );
  };

  const makeAsMasterClicked = (itm) => {
    if (type === 'center' || type === 'parent') {
      const newList = data.centerTwoData.filter((center) => center.id !== itm.id)
      newList.push(data.centerOneData)
      const newIdList = []
      newList.map((center) => newIdList.push(center.id))
      setCenterForMerging({
        centerOneId: itm.id,
        centerTwoId: newIdList,
        centerOneData: itm,
        centerTwoData: newList,
      })
      dispatch(mergePreRequestAction({
        type: 'SITE',
        body: {
          source_id: newIdList,
          target_id: itm.id,
        },
      }))
    }
    if (type === 'investigator') {
      const newList = data.piTwoData.filter((pi) => pi.id !== itm.id)
      newList.push(data.piOneData)
      const newIdList = []
      newList.map((pi) => newIdList.push(pi.id))
      setPiForMerging({
        piOneId: itm.id,
        piTwoId: newIdList,
        piOneData: itm,
        piTwoData: newList,
      })
      dispatch(mergePreRequestAction({
        type: 'PI',
        body: {
          source_id: newIdList,
          target_id: itm.id,
        },
      }))
    }
  }

  const onCenterCheckboxClicked = (itm, field) => {
    switch (field) {
      case 'name':
        if (itm.id === centerUpdatedFields.name.doc_id) {
          setCenterUpdatedFields({ ...centerUpdatedFields, name: { doc_id: undefined, value: undefined } })
        } else { setCenterUpdatedFields({ ...centerUpdatedFields, name: { doc_id: itm.id, value: itm.name } }) }
        break
      case 'email':
        if (itm.id === centerUpdatedFields.email.doc_id) {
          setCenterUpdatedFields({ ...centerUpdatedFields, email: { doc_id: undefined, value: undefined } })
        } else {
          setCenterUpdatedFields({ ...centerUpdatedFields, email: { doc_id: itm.id, value: itm.email } })
        }
        break

      case 'phone_number':
        if (itm.id === centerUpdatedFields.phone_number.doc_id) {
          setCenterUpdatedFields({ ...centerUpdatedFields, phone_number: { doc_id: undefined, value: undefined } })
        } else {
          setCenterUpdatedFields({ ...centerUpdatedFields, phone_number: { doc_id: itm.id, value: itm.phone_number } })
        }
        break
      case 'address':
        if (itm.id === centerUpdatedFields.address.doc_id) {
          setCenterUpdatedFields({ ...centerUpdatedFields, address: { doc_id: undefined, value: undefined } })
        } else {
          setCenterUpdatedFields({ ...centerUpdatedFields, address: { doc_id: itm.id, value: itm.address } })
        }
        break
      case 'department':
        if (itm.id === centerUpdatedFields?.department?.doc_id) {
          setCenterUpdatedFields({ ...centerUpdatedFields, department: { doc_id: undefined, value: undefined } })
        } else {
          setCenterUpdatedFields({ ...centerUpdatedFields, department: { doc_id: itm.id, value: itm.department } })
        }
        break
      default: return null
    }

    return undefined
  }

  const onPiCheckboxClicked = (itm, field) => {
    switch (field) {
      case 'name':
        if (itm.id === piUpdatedFields.name.doc_id) {
          setPiUpdatedFields({ ...piUpdatedFields, name: { doc_id: undefined, value: undefined } })
        } else { setPiUpdatedFields({ ...piUpdatedFields, name: { doc_id: itm.id, value: itm.name } }) }
        break
      case 'email':
        if (itm.id === piUpdatedFields.primary_email.doc_id) {
          setPiUpdatedFields({ ...piUpdatedFields, primary_email: { doc_id: undefined, value: undefined } })
        } else {
          setPiUpdatedFields({ ...piUpdatedFields, primary_email: { doc_id: itm.id, value: itm.primary_email } })
        }
        break

      case 'phone_number':
        if (itm.id === piUpdatedFields.phone_number.doc_id) {
          setPiUpdatedFields({ ...piUpdatedFields, phone_number: { doc_id: undefined, value: undefined } })
        } else {
          setPiUpdatedFields({ ...piUpdatedFields, phone_number: { doc_id: itm.id, value: itm.phone_number } })
        }
        break
      case 'address':
        if (itm.id === piUpdatedFields.address.doc_id) {
          setPiUpdatedFields({ ...piUpdatedFields, address: { doc_id: undefined, value: undefined } })
        } else {
          setPiUpdatedFields({ ...piUpdatedFields, address: { doc_id: itm.id, value: itm.address } })
        }
        break
      case 'survey_email':
        if (itm.id === piUpdatedFields.survey_email.doc_id) {
          setPiUpdatedFields({ ...piUpdatedFields, survey_email: { doc_id: undefined, value: undefined } })
        } else {
          setPiUpdatedFields({ ...piUpdatedFields, survey_email: { doc_id: itm.id, value: itm.survey_email } })
        }
        break
      default: return null
    }

    return undefined
  }

  const getCenterCard = (itm, typeOfCard) => {
    console.log('itm--->', itm)
    if (itm !== undefined) {
      return (
        <div className={`merge-dupl-container-sec-card ${typeOfCard === 'merged' ? 'active' : null}`}>
          <div className="investigator-merge-dupl-container-sec-card-cnt">
            <div className="investigator-merge-dupl-container-sec-card-cnt-head-title">
              {typeOfCard !== 'merged' && preRequest && <Checkbox onClick={() => onCenterCheckboxClicked(itm, 'name')} checked={itm.id === centerUpdatedFields.name.doc_id} />}
              <div className="merge-dupl-container-sec-card-title">{itm.name}</div>
            </div>
            <div className="merge-dupl-container-sec-card-content">
              <div className="merge-dupl-container-sec-card-content-data contact-name">
                <div className="merge-dupl-container-sec-card-content-data-value">
                  {typeOfCard !== 'merged' && preRequest && itm.address && <Checkbox onClick={() => onCenterCheckboxClicked(itm, 'address')} checked={itm.id === centerUpdatedFields.address.doc_id} />}
                  <div className="merge-dupl-container-sec-card-content-data-combine">
                    <div className="merge-dupl-container-sec-card-content-data-title">
                      Address:
                    </div>
                    {itm.address
                      ? (
                        <>
                          <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                            {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                            {itm?.address?.city && `${itm?.address?.city}, `}
                            {itm?.address?.state && `${itm?.address?.state}, `}
                            {itm?.address?.country && `${itm?.address?.country}, `}
                            {itm?.address?.zip}
                          </div>
                          {/* </div> */}
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="merge-dupl-container-sec-card-content-data email">
                <div className="merge-dupl-container-sec-card-content-data-value">
                  {typeOfCard !== 'merged' && preRequest && itm.email && itm.email.length !== 0 && <Checkbox onClick={() => onCenterCheckboxClicked(itm, 'email')} checked={itm.id === centerUpdatedFields.email.doc_id} />}
                  <div className="merge-dupl-container-sec-card-content-data-combine">
                    <div className="merge-dupl-container-sec-card-content-data-title"> Email:</div>
                    {itm.email && itm.email.length
                      ? (
                        <>
                          <div className="content-child">{itm.email.join(', ')}</div>
                          {/* </div> */}
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="merge-dupl-container-sec-card-content-data phone">
                <div className="merge-dupl-container-sec-card-content-data-value">
                  {typeOfCard !== 'merged' && preRequest && itm.phone_number && itm.phone_number.length !== 0 && <Checkbox onClick={() => onCenterCheckboxClicked(itm, 'phone_number')} checked={itm.id === centerUpdatedFields.phone_number.doc_id} />}
                  <div className="merge-dupl-container-sec-card-content-data-combine">
                    <div className="merge-dupl-container-sec-card-content-data-title">Phone:</div>
                    {itm.phone_number && itm.phone_number.length
                      ? (
                        <>
                          <div className="content-child">{itm.phone_number.join(', ')}</div>
                          {/* </div> */}
                        </>
                      )
                      : (
                        <div className="content-child no-data">No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="merge-dupl-container-sec-card-content-data phone">
                <div className="merge-dupl-container-sec-card-content-data-value">
                  {typeOfCard !== 'merged' && preRequest && itm?.department && <Checkbox onClick={() => onCenterCheckboxClicked(itm, 'department')} checked={itm.id === centerUpdatedFields?.department?.doc_id} />}
                  <div className="merge-dupl-container-sec-card-content-data-combine">
                    <div className="merge-dupl-container-sec-card-content-data-title">Department:</div>
                    {itm?.department
                      ? (
                        <>
                          <div className="content-child">{itm.department}</div>
                          {/* </div> */}
                        </>
                    )
                      : (
                        <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="info-ratings-values">
                <div className="info-ratings-values-title">Reliability</div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    {itm?.reliability && itm?.reliability !== ''
                      ? (
                        <StarRatings
                          rating={itm?.reliability}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="info-ratings-values">
                <div className="info-ratings-values-title">Quality</div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    {itm?.quality && itm?.quality !== ''
                      ? (
                        <StarRatings
                          rating={itm?.quality}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      )
                      : (
                        <div className="content-child no-data">&nbsp;No data available</div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="merge-dupl-container-sec-card-tag-sec">
              <div className="tags-data">
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.tier}</div>
                    <div className="sizeOftag">{itm.tier ? itm.tier : '-'}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.alliance}</div>
                    <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.compliance}</div>
                    <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                    <div className="sizeOftag">{itm.total_number_of_popis}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.industry_studies}</div>
                    <div className="sizeOftag">{itm.industry_studies}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                    <div className="sizeOftag">{itm.interventional_studies}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.observational_studies}</div>
                    <div className="sizeOftag">{itm.observational_studies}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                    <div className="sizeOftag">{itm.parexel_studies}</div>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                    <span className="sizeOftag">
                      {itm.enrollment_subjects}
                    </span>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">{tagTitle.indications}</div>
                    <Tooltip
                      placement="bottom"
                      title={
                        itm.indications && itm.indications.length
                          ? itm.indications.join('; ')
                          : '-'
                      }
                    >
                      <span className="sizeOftag">
                        {itm.indications && itm.indications.length
                          ? itm.indications.join('; ')
                          : '-'}
                      </span>
                    </Tooltip>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">Sources&nbsp;</div>
                    <Tooltip
                      placement="bottom"
                      title={
                        itm.sources && itm.sources.length
                          ? itm.sources.join(', ')
                          : '-'
                      }
                    >
                      <span className="sizeOftag">
                        {itm.sources && itm.sources.length
                          ? itm.sources.join(', ')
                          : '-'}
                      </span>
                    </Tooltip>
                  </div>
                </Tag>
                <Tag>
                  <div className="details-abt-tags">
                    <div className="nameOfTag">Center type&nbsp;</div>
                    <Tooltip
                      placement="bottom"
                      title={
                        itm.center_type
                          ? itm.center_type
                          : '-'
                      }
                    >
                      <span className="sizeOftag">
                        {itm.center_type
                          ? itm.center_type
                          : '-'}
                      </span>
                    </Tooltip>
                  </div>
                </Tag>
                {itm.pxl_center_sims_ids
                  ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">SIMS IDs&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                              ? itm.pxl_center_sims_ids.join(', ')
                              : '-'
                          }
                        >
                          <span className="sizeOftag">
                            {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  )
                  : null}
                {itm.pxl_center_cit_ids

                  ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Citeline IDs&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                              ? itm.pxl_center_cit_ids.join(', ')
                              : '-'
                          }
                        >
                          <span className="sizeOftag">
                            {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  )
                  : null}
                {itm.landscape_id

                  ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.landscape_id
                              ? itm.landscape_id
                              : '-'
                          }
                        >
                          <span className="sizeOftag">
                            {itm.landscape_id
                              ? itm.landscape_id
                              : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  )
                  : null}
              </div>
            </div>
          </div>
          {view === 'user' && itm.id !== data.centerOneId && <div className="makeasmaster-btn"><Button type="primary" onClick={() => makeAsMasterClicked(itm)} className="make-as-master-btn">Select as Master</Button></div>}
        </div>
      )
    }
    return null
  }

  const getPiCard = (itm, typeOfCard) => {
    if (itm !== undefined) {
      return (
        <div className="investigator-merge-modal">
          <div className="investigator-merge-dupl-container merge-dupl-container">
            <div className="investigator-merge-dupl-container-sec">
              <div className={`investigator-merge-dupl-container-sec-card  ${typeOfCard === 'merged' ? 'active' : null} merge-dupl-container-sec-card`}>
                <div className="investigator-merge-dupl-container-sec-card-cnt">
                  <div className="investigator-merge-dupl-container-top">
                    <div className="investigator-merge-dupl-container-top-combine">
                      <div className="investigator-merge-dupl-container-top-combine-desc">
                        <div className="investigator-merge-dupl-container-sec-card-cnt-head-title">
                          {typeOfCard !== 'merged' && preRequest && <Checkbox onClick={() => onPiCheckboxClicked(itm, 'name')} checked={itm.id === piUpdatedFields.name.doc_id} />}
                          <div className="investigator-merge-dupl-container-top-combine-desc-name">
                            {itm.name}
                          </div>
                        </div>
                        {itm?.address?.address_line1 || itm?.address?.address_line2 || itm?.address?.city || itm?.address?.state || itm?.address?.country || itm?.address?.zip ? (
                          <div className="investigator-merge-dupl-container-top-combine-desc-content">
                            <div className="merge-dupl-container-sec-card-content-data-value">
                              {typeOfCard !== 'merged' && preRequest && <Checkbox onClick={() => onPiCheckboxClicked(itm, 'address')} checked={itm.id === piUpdatedFields.address.doc_id} />}
                              <div className="t-title">Address :</div>
                              <div className="t-desc">
                                <div>{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                                  {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                                  {itm?.address?.city && `${itm?.address?.city}, `}
                                  {itm?.address?.state && `${itm?.address?.state}, `}
                                  {itm?.address?.country && `${itm?.address?.country}, `}
                                  {itm?.address?.zip}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                          : null}
                        <div className="investigator-merge-dupl-container-top-combine-desc-content">
                          <div className="t-title">Primary Center:</div>
                          <div className="t-desc">{itm.affiliated_site ? itm.affiliated_site : <span className="value" style={{ fontSize: '14px' }}>No data available</span>}</div>
                        </div>
                        <div className="investigator-merge-dupl-container-top-combine-desc-content">
                          <div className="t-title">Survey Email </div>
                          <div className="t-desc">
                            {itm.survey_email && itm.survey_email.length ? (
                              <Tooltip placement="bottom" title={itm.survey_email.join(', ')}>
                                {typeOfCard !== 'merged' && preRequest && <Checkbox onClick={() => onPiCheckboxClicked(itm, 'survey_email')} checked={itm.id === piUpdatedFields.survey_email.doc_id} />}
                                <span className="value">{itm.survey_email.join(', ')}</span>
                              </Tooltip>
                            ) : (
                              <span className="value" style={{ fontSize: '14px' }}>No data available</span>
                            )}
                          </div>
                        </div>
                        <div className="investigator-merge-dupl-container-top-combine-desc-combine">
                          <div className="investigator-merge-dupl-container-top-combine-desc-content">
                            <div className="t-title">Primary Email: </div>
                            <div className="t-desc">
                              <div className="merge-dupl-container-sec-card-content-data email">
                                {itm?.primary_email && itm?.primary_email.length ? (
                                  <Tooltip placement="bottom" title={itm.primary_email}>
                                    {typeOfCard !== 'merged' && preRequest && <Checkbox onClick={() => onPiCheckboxClicked(itm, 'email')} checked={itm.id === piUpdatedFields.primary_email.doc_id} />}
                                    <span className="value">{itm?.primary_email}</span>
                                  </Tooltip>
                                ) : (
                                  <span className="value" style={{ fontSize: '14px' }}>No data available</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="investigator-merge-dupl-container-top-combine-desc-content">
                            <div className="t-title">Phone: </div>
                            <div className="t-desc">
                              {itm.phone_number.length ? (
                                <Tooltip placement="bottom" title={itm.phone_number.join(', ')}>
                                  {typeOfCard !== 'merged' && preRequest && <Checkbox onClick={() => onPiCheckboxClicked(itm, 'phone_number')} checked={itm.id === piUpdatedFields.phone_number.doc_id} />}
                                  <span className="value">{itm.phone_number.join(', ')}</span>
                                </Tooltip>
                              ) : (
                                <span className="value" style={{ fontSize: '14px' }}>No data available</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="investigator-merge-dupl-container-top-combine-desc-rating">
                          <div className="t-title">Recruitment</div>
                          <div className="t-desc">
                            {itm?.recruitment && itm?.recruitment !== ''
                              ? (
                                <StarRatings
                                  rating={parseInt(itm?.recruitment, 10)}
                                  starRatedColor="#8A0050"
                                  numberOfStars={3}
                                  starDimension="16px"
                                  starSpacing="1px"
                                  starEmptyColor="#D099B9"
                                />
                              )
                              : (
                                <div className="dash-nodata">-</div>
                              )}
                          </div>
                        </div>
                        <div className="investigator-merge-dupl-container-top-combine-desc-rating">
                          <div className="t-title">SIMS Id : </div>
                          <div className="t-desc">
                            {itm?.pxl_author_sims_ids && itm?.pxl_author_sims_ids.length ? itm.pxl_author_sims_ids.join(',')
                              : (
                                <div className="dash-nodata">-</div>
                              )}
                          </div>
                        </div>
                        <div className="investigator-merge-dupl-container-top-combine-desc-rating">
                          <div className="t-title">Citeline Id : </div>
                          <div className="t-desc">
                            {itm?.pxl_author_cit_ids && itm?.pxl_author_cit_ids.length ? itm.pxl_author_cit_ids.join(',')
                              : (
                                <div className="dash-nodata">-</div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="investigator-merge-modal-info-ratings-values">
                      <div className="investigator-merge-modal-info-ratings-values-con">
                        <div className="investigator-merge-modal-info-ratings-values-con-title">
                          Industry studies:
                        </div>
                        <div className="investigator-merge-modal-info-ratings-values-con-data">{itm.industry_studies}</div>
                      </div>
                      <div className="investigator-merge-modal-info-ratings-values-con">
                        <div className="investigator-merge-modal-info-ratings-values-con-title">
                          Parexel studies:
                        </div>
                        <div className="investigator-merge-modal-info-ratings-values-con-data">{itm.parexel_studies}</div>
                      </div>
                      <div className="investigator-merge-modal-info-ratings-values-con">
                        <div className="investigator-merge-modal-info-ratings-values-con-title">
                          Enrolled subjects:
                        </div>
                        <div className="investigator-merge-modal-info-ratings-values-con-data">
                          {itm.enrollment_subjects && itm.enrollment_subjects !== ''
                            ? (
                              <div className="info-ratings-values-data">{itm.enrollment_subjects}</div>
                            )
                            : (
                              <div className="info-ratings-values-data">0</div>
                            )}
                        </div>
                      </div>
                      <div className="investigator-merge-modal-info-ratings-values-con">
                        <div className="investigator-merge-modal-info-ratings-values-con-title">
                          Tier:
                        </div>
                        <div className="investigator-merge-modal-info-ratings-values-con-data">
                          {itm.tier ? <div className="info-ratings-values-data cursor-click">{itm.tier}</div> : <div className="dash-nodata">-</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="investigator-merge-dupl-container-bottom">
                    <div className="graph-data-sec">
                      <div className="graph-data-sec-value-pie-chart">
                        <div className="pie-chart">
                          {showPieChart(itm?.indication_pie_chart) ? (
                            <BarChart
                              data={itm?.indication_pie_chart?.slice(0, 10)}
                              width={160}
                              height={120}
                              top={20}
                              bottom={20}
                              left={40}
                              right={20}
                              studies="indication"
                              checkDataset="investigators"
                              hideLabels
                            />
                          ) : (
                            <span>No data available</span>
                          )}
                        </div>
                      </div>
                      <div className="graph-data-sec-value-content">
                        <div className="investigator-info-ratings-values-con">
                          <div className="investigator-info-ratings-values-con-title">
                            Ongoing Studies:
                          </div>
                          <div className="investigator-info-ratings-values-con-data">{itm.ongoing_studies}</div>
                        </div>
                        <div className="investigator-info-ratings-values-con">
                          <div className="investigator-info-ratings-values-con-title">Indications:</div>
                          <div className="investigator-info-ratings-values-con-data">
                            <Tooltip
                              placement="bottom"
                              title={
                                itm.indications && itm.indications.length
                                  ? itm.indications.join('; ')
                                  : '-'
                              }
                            >
                              <span className="sizeOftag">
                                {itm.indications && itm.indications.length
                                  ? itm.indications.join('; ')
                                  : '-'}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {view === 'user' && itm.id !== data.piOneId && <div className="makeasmaster-btn"><Button type="primary" onClick={() => makeAsMasterClicked(itm)} className="make-as-master-btn">Select as Master</Button></div>}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null
  }

  const onBtnClicked = (btnType) => {
    switch (btnType) {
      case 'rejected': setConfirmationModal(true)
        break
      case 'approved': dispatch(approvalMergeAction(
        {
          type: type === 'investigator' ? 'PI' : 'SITE',
          innoplexus_id: mergedId,
          body: {
            status_flag: 'Approved',
          },
        },
      ))
        setConfirmationModal(false)
        setIsMergeModalOpen(false)
        break
      case 'rejectdone': dispatch(approvalMergeAction(
        {
          type: type === 'investigator' ? 'PI' : 'SITE',
          innoplexus_id: mergedId,
          body: {
            status_flag: 'Rejected',
          },
        },
      ))
        setConfirmationModal(false)
        setIsMergeModalOpen(false)
        break
      case 'rejectcancel': setConfirmationModal(false)
        break
      default: return null
    }
    return undefined
  }
  return (
    <>
      <Modal
        title="Merge duplicates"
        visible={isMergeModalOpen}
        onCancel={() => setIsMergeModalOpen(false)}
        okText="Confirm"
        cancelButtonProps={{ style: { display: 'none' } }}
        className="merge-modal"
        footer={null}
      >
        {type === 'center' && view === 'user' ? (
          <Loader loading={mergePreRequestData.loading} error={mergePreRequestData.error}>

            <div className="merge-dupl-container">
              <div className="merge-dupl-container-scroll">
                <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Master Center</div>
                  {getCenterCard(data.centerOneData)}
                </div>
                {data.centerTwoData.map((itm, index) => (
                  <div className="merge-dupl-container-sec">
                    <div className="merge-dupl-container-sec-title">Duplicate {index + 1}</div>
                    {getCenterCard(itm, true)}
                  </div>
                ))}
              </div>
              <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Center</div>
                {mergePreRequestData && mergePreRequestData.data && mergePreRequestData.data.merged_doc && getCenterCard(mergePreRequestData.data.merged_doc, 'merged')}
              </div>
            </div>
            <div className="merge-modal-footer">
              <Button className="merge-modal-confirm-btn" onClick={() => onConfirmClick(mergePreRequestData?.data)}>Confirm</Button>
            </div>
          </Loader>

        ) : type === 'center' && view === 'admin'
          ? (
            <Loader loading={getMergedDoc.loading || getCardData.loading} error={mergePreRequestData.error}>
              <Comment type="request_site" action_type="Merge" innoplexusId={mergedId} count={commentCnt} />
              <div className="merge-dupl-container">
                {/* <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Center 1</div>
                  {getCardData && getCardData.data && getCardData.data.source && getCenterCard(getCardData.data.source)}
                </div>
                <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Center 2</div>
                  {getCardData && getCardData.data && getCardData.data.target && getCenterCard(getCardData.data.target)}
                </div>
                <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Center</div>
                  {getMergedDoc && getMergedDoc.data && getMergedDoc.data.merged_doc && getCenterCard(getMergedDoc.data.merged_doc, 'merged')}
                </div> */}
                <div className="merge-dupl-container-scroll">
                  <div className="merge-dupl-container-sec">
                    <div className="merge-dupl-container-sec-title">Master Center</div>
                    {getCardData && getCardData.data && getCardData.data.target_document && getCardData.data.target_document[0] && getCenterCard(getCardData.data.target_document[0])}
                  </div>
                  {getCardData && getCardData.data && getCardData.data.other_documents && getCardData.data.other_documents.map((itm, index) => (
                    <div className="merge-dupl-container-sec">
                      <div className="merge-dupl-container-sec-title">Duplicate {index + 1}</div>
                      {getCenterCard(itm, true)}
                    </div>
                  ))}
                </div>
                <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Center</div>
                  {getMergedDoc && getMergedDoc.data && getMergedDoc.data.merged_doc && getCenterCard(getMergedDoc.data.merged_doc, 'merged')}
                </div>
              </div>
              {status === 'Pending' && (adminType.includes('super_admin') || adminType.includes('sfl_access') || adminType.includes('sam_access')) && !fromReports
                ? (
                  <div className="merge-modal-footer">
                    <Button className="merge-modal-reject-btn" onClick={() => onBtnClicked('rejected')}>Reject</Button>
                    <Button className="merge-modal-approve-btn" onClick={() => onBtnClicked('approved')}>Approve</Button>
                  </div>
                ) : (
                  <div className="merge-modal-footer">
                    <Button className="merge-modal-approve-btn" onClick={() => setIsMergeModalOpen(false)}>Close</Button>
                  </div>
                )}
            </Loader>
          ) : null}
        {type === 'parent' && view === 'user' ? (
          <Loader loading={mergePreRequestData.loading} error={mergePreRequestData.error}>

            <div className="merge-dupl-container">
              <div className="merge-dupl-container-scroll">
                <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Master Parent Institution</div>
                  {getCenterCard(data.centerOneData)}
                </div>
                {data.centerTwoData.map((itm, index) => (
                  <div className="merge-dupl-container-sec">
                    <div className="merge-dupl-container-sec-title">Duplicate {index + 1}</div>
                    {getCenterCard(itm, true)}
                  </div>
                ))}
              </div>
              <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Parent Institution</div>
                {mergePreRequestData && mergePreRequestData.data && mergePreRequestData.data.merged_doc && getCenterCard(mergePreRequestData.data.merged_doc, 'merged')}
              </div>
            </div>
            <div className="merge-modal-footer">
              <Button className="merge-modal-confirm-btn" onClick={() => onConfirmClick(mergePreRequestData?.data)}>Confirm</Button>
            </div>
          </Loader>

        ) : type === 'parent' && view === 'admin'
          ? (
            <Loader loading={getMergedDoc.loading || getCardData.loading} error={mergePreRequestData.error}>
              <Comment type="request_site" action_type="Merge" innoplexusId={mergedId} count={commentCnt} />
              <div className="merge-dupl-container">
                {/* <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Center 1</div>
                  {getCardData && getCardData.data && getCardData.data.source && getCenterCard(getCardData.data.source)}
                </div>
                <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Center 2</div>
                  {getCardData && getCardData.data && getCardData.data.target && getCenterCard(getCardData.data.target)}
                </div>
                <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Center</div>
                  {getMergedDoc && getMergedDoc.data && getMergedDoc.data.merged_doc && getCenterCard(getMergedDoc.data.merged_doc, 'merged')}
                </div> */}
                <div className="merge-dupl-container-scroll">
                  <div className="merge-dupl-container-sec">
                    <div className="merge-dupl-container-sec-title">Master Parent Institution</div>
                    {getCardData && getCardData.data && getCardData.data.target_document && getCardData.data.target_document[0] && getCenterCard(getCardData.data.target_document[0])}
                  </div>
                  {getCardData && getCardData.data && getCardData.data.other_documents && getCardData.data.other_documents.map((itm, index) => (
                    <div className="merge-dupl-container-sec">
                      <div className="merge-dupl-container-sec-title">Duplicate {index + 1}</div>
                      {getCenterCard(itm, true)}
                    </div>
                  ))}
                </div>
                <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Parent Institution</div>
                  {getMergedDoc && getMergedDoc.data && getMergedDoc.data.merged_doc && getCenterCard(getMergedDoc.data.merged_doc, 'merged')}
                </div>
              </div>
              {status === 'Pending' && (adminType.includes('super_admin') || adminType.includes('sfl_access') || adminType.includes('sam_access')) && !fromReports
                ? (
                  <div className="merge-modal-footer">
                    <Button className="merge-modal-reject-btn" onClick={() => onBtnClicked('rejected')}>Reject</Button>
                    <Button className="merge-modal-approve-btn" onClick={() => onBtnClicked('approved')}>Approve</Button>
                  </div>
                ) : (
                  <div className="merge-modal-footer">
                    <Button className="merge-modal-approve-btn" onClick={() => setIsMergeModalOpen(false)}>Close</Button>
                  </div>
                )}
            </Loader>
          ) : null}
        {type === 'investigator' && view === 'user' ? (
          <Loader loading={mergePreRequestData.loading} error={mergePreRequestData.error}>
            <div className="merge-dupl-container">
              <div className="merge-dupl-container-scroll">
                <div className="merge-dupl-container-sec">
                  <div className="merge-dupl-container-sec-title">Master Investigator</div>
                  {getPiCard(data.piOneData)}
                </div>
                {data.piTwoData.map((itm, index) => (
                  <div className="merge-dupl-container-sec">
                    <div className="merge-dupl-container-sec-title">Duplicate {index + 1}</div>
                    {getPiCard(itm, true)}
                  </div>
                ))}
              </div>
              <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Investigator</div>
                {mergePreRequestData && mergePreRequestData.data && mergePreRequestData.data.merged_doc && getPiCard(mergePreRequestData.data.merged_doc, 'merged')}
              </div>
            </div>
            <div className="merge-modal-footer">
              <Button className="merge-modal-confirm-btn" onClick={() => onConfirmClick(mergePreRequestData?.data)}>Confirm</Button>
            </div>
          </Loader>

        ) : type === 'investigator' && view === 'admin'
          ? (
            <Loader loading={getMergedDoc.loading || getCardData.loading} error={mergePreRequestData.error}>
              <Comment type="request_site" action_type="Merge" innoplexusId={mergedId} count={commentCnt} />
              <div className="merge-dupl-container">
                <div className="merge-dupl-container-scroll">
                  <div className="merge-dupl-container-sec">
                    <div className="merge-dupl-container-sec-title">Master Investigator</div>
                    {getCardData && getCardData.data && getCardData.data.target_document && getCardData.data.target_document[0] && getPiCard(getCardData.data.target_document[0])}
                  </div>
                  {getCardData && getCardData.data && getCardData.data.other_documents && getCardData.data.other_documents.map((itm, index) => (
                    <div className="merge-dupl-container-sec">
                      <div className="merge-dupl-container-sec-title">Duplicate {index + 1}</div>
                      {getPiCard(itm, true)}
                    </div>
                  ))}
                </div>
                <div className="merge-dupl-container-sec"><div className="merge-dupl-container-sec-title">Merged Investigator</div>
                  {getMergedDoc && getMergedDoc.data && getMergedDoc.data.merged_doc && getPiCard(getMergedDoc.data.merged_doc, 'merged')}
                </div>
              </div>
              {status === 'Pending' && (adminType.includes('super_admin') || adminType.includes('sfl_access') || adminType.includes('sam_access')) && !fromReports
                ? (
                  <div className="merge-modal-footer">
                    <Button className="merge-modal-reject-btn" onClick={() => onBtnClicked('rejected')}>Reject</Button>
                    <Button className="merge-modal-approve-btn" onClick={() => onBtnClicked('approved')}>Approve</Button>
                  </div>
                ) : (
                  <div className="merge-modal-footer">
                    <Button className="merge-modal-approve-btn" onClick={() => setIsMergeModalOpen(false)}>Close</Button>
                  </div>
                )}
            </Loader>
          ) : null}

      </Modal>
      {confirmationModal && (
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Confirmation"
          visible={confirmationModal}
          className="del-modal-wrapper conf-modal-wrapper"
          onCancel={() => {
            setConfirmationModal(false);
          }}
          destroyOnClose
          footer={null}
        >
          <div className="conf-desc"> Have you added comments explaining changes required in the request?</div>
          <div className="footer-btn">
            <Button
              className="delete-button"
              type="primary"
              onClick={() => onBtnClicked('rejectdone')}
            >Yes
            </Button>
            <Button
              className="delete-button"
              type="primary"
              onClick={() => onBtnClicked('rejectcancel')}
            >No
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default MergeViewModal
